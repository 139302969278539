import { usePathname, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import { getKsLocation, KsLocation } from './ks-location';

export function useKsLocation(): KsLocation {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  return useMemo(() => {
    return getKsLocation({ pathname, searchParams });
  }, [pathname, searchParams]);
}
