'use client';

import { useAppClientDataCtxSelector } from '@/core';
import { getThemeStyles } from '@/utils/ui-config';

export function ClientHeaders() {
  const workspaceAppearanceStyles = useAppClientDataCtxSelector((ctx) => {
    const uiConfig = ctx.active.db.settings.theme;
    const { tokensById } = ctx.tokenData;
    const styles = getThemeStyles({
      uiConfig,
      tokensById,
      selector: ':root',
    });
    return styles;
  });

  return <style id="theme-styles">{workspaceAppearanceStyles}</style>;
}
