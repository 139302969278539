import { Entries, kebabCase as loKebab } from '@knapsack/utils';
import {
  PrimaryNavCustomizationConfigStruct,
  SecondaryNavCustomizationConfigStruct,
  ColorConfigStruct,
  FontSizeConfigStruct,
  TokenData,
  ColorStringStruct,
  UiConfig,
} from '@knapsack/types';
import { UiSettings, defaultUiConfig } from './ui-settings';
import { migrateUiConfig } from './ui-config-migrate';

export function entries<T>(obj: T): Entries<T> {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  return Object.entries(obj) as any;
}

export type UiConfigConversionError = {
  message: string;
  uiConfigKey?: keyof UiConfig;
};

export function convertUiConfigToUiSettings({
  uiConfig,
}: {
  uiConfig: UiConfig;
}): {
  errors: UiConfigConversionError[];
  uiSettings: UiSettings;
} {
  const errors: ReturnType<typeof convertUiConfigToUiSettings>['errors'] = [];

  const config = migrateUiConfig(uiConfig);

  /**
   * End functions to determine each uiSettings key
   */

  const uiSettings: UiSettings = {
    appearance: {
      pageElements: config.appearance.pageElements,
      customFonts: config.appearance.customFonts,
      navigation: config.appearance.navigation,
    },
  };

  return {
    errors,
    uiSettings,
  };
}

const { errors, uiSettings: defaultUiSettings } = convertUiConfigToUiSettings({
  uiConfig: defaultUiConfig,
});

if (errors.length > 0) {
  throw new Error(
    `Cannot have any errors when converting "defaultUiConfig" to "defaultUiSettings": ${errors
      .map((e) => e.message)
      .join('. ')}`,
  );
}

export function getCustomThemeVar({
  elName,
  property,
  value,
}: {
  elName: string;
  property: string;
  value: string | number;
}): string {
  return `--${`knapsack-custom-theme--${elName}--${loKebab(
    property,
  )}`}: ${value};`;
}

export const convertUiTypographySettingsToCss = ({
  uiTypographySettings,
  tokensById,
}: {
  uiTypographySettings: UiSettings['appearance']['pageElements'];
  tokensById: Record<string, TokenData>;
}): string => {
  if (!uiTypographySettings) return '';

  const typographyCss = entries(uiTypographySettings)
    // filter out items (the second item) that are undefined
    .map(([elementName, elementSettings]) => {
      const elName = loKebab(elementName);

      // exit early if no actual settings for that element
      if (elementSettings === undefined) return null;

      if (elementName === 'link') {
        return entries(elementSettings)
          .map(([key, val]) => {
            if (val === undefined) return null;
            if (val === '') return null;

            if (key === 'underline') {
              return getCustomThemeVar({
                elName,
                property: key,
                value: val ? 'underline' : 'none',
              });
            }

            if (key === 'textColor' || key === 'hoverColor') {
              switch (val.type) {
                case 'value': {
                  if (ColorStringStruct.is(val.colorValue)) {
                    return getCustomThemeVar({
                      elName,
                      property: key,
                      value: val.colorValue,
                    });
                  }
                  break;
                }
                case 'design-token': {
                  if ('tokenName' in val) {
                    // Run the token name through the converter to force dash-
                    // separated (old) names to dot-separated names (v4)
                    const tokenId = val.tokenName;
                    const token = tokensById[tokenId];
                    if (!token) {
                      console.warn('Missing token: ', val);
                      return '';
                    }
                    if (token.type === 'color') {
                      return getCustomThemeVar({
                        elName,
                        property: key,
                        value: token.value,
                      });
                    }
                  }
                  break;
                }
                default:
                  return '';
              }
            } else {
              return getCustomThemeVar({
                elName,
                property: key,
                value: val,
              });
            }

            return '';
          })
          .filter(Boolean)
          .join('\n');
      }

      return entries(elementSettings)
        .map(([key, val]) => {
          if (val === undefined) return null;
          if (val === '') return null;

          // separately handle the min / max syntax used in font size
          // @todo: consider a helper util for stuff like this post-MVP
          if (FontSizeConfigStruct.is(val)) {
            if (val?.min) {
              return getCustomThemeVar({
                elName,
                property: key,
                value: `${val.min.value}${val.min.unit};`,
              });
            }
          } else if (key === 'textColor') {
            switch (val.type) {
              case 'value': {
                if (ColorStringStruct.is(val.colorValue)) {
                  return getCustomThemeVar({
                    elName,
                    property: key,
                    value: val.colorValue,
                  });
                }
                break;
              }
              case 'design-token': {
                if ('tokenName' in val) {
                  const tokenId = val.tokenName;
                  const token = tokensById[tokenId];
                  if (!token) {
                    console.warn('Missing token: ', val);
                    return '';
                  }
                  if (token.type === 'color') {
                    return getCustomThemeVar({
                      elName,
                      property: key,
                      value: token.value,
                    });
                  }
                }
                break;
              }
              default:
                return '';
            }
          } else {
            return getCustomThemeVar({
              elName,
              property: key,
              value: val,
            });
          }

          return '';
        })
        .filter(Boolean)
        .join('\n');
    })
    .join('\n');

  return typographyCss;
};

export const convertUiNavSettingsToCss = ({
  uiNavSettings,
  tokensById,
}: {
  uiNavSettings: UiSettings['appearance']['navigation'];
  tokensById: Record<string, TokenData>;
}): string => {
  if (!uiNavSettings) return '';

  const typographyCss = entries(uiNavSettings)
    // filter out items (the second item) that are undefined
    .map(([elementName, elementSettings]) => {
      const elName = loKebab(elementName);

      // exit early if no settings value for this particular bit of UI
      if (elementSettings === undefined) return null;

      if (
        PrimaryNavCustomizationConfigStruct.is(elementSettings) ||
        SecondaryNavCustomizationConfigStruct.is(elementSettings)
      ) {
        return entries(elementSettings)
          .map(([key, val]) => {
            if (val === undefined) return null;
            if (val === '') return null;

            if (ColorConfigStruct.is(val)) {
              switch (val.type) {
                case 'design-token': {
                  if ('tokenName' in val) {
                    const tokenId = val.tokenName;
                    const token = tokensById[tokenId];
                    if (!token) {
                      console.warn('Missing token: ', val);
                      return '';
                    }
                    if (token.type === 'color') {
                      return getCustomThemeVar({
                        elName,
                        property: key,
                        value: token.value,
                      });
                    }
                  }
                  break;
                }
                case 'value':
                default: {
                  if (ColorStringStruct.is(val.colorValue)) {
                    return getCustomThemeVar({
                      elName,
                      property: key,
                      value: val.colorValue,
                    });
                  }
                  break;
                }
              }
            }

            if (FontSizeConfigStruct.is(val)) {
              if ('min' in val) {
                if (val?.min) {
                  return getCustomThemeVar({
                    elName,
                    property: key,
                    value: `${val.min.value}${val.min.unit}`,
                  });
                }
              }
            }

            if (typeof val === 'string' || typeof val === 'number') {
              return getCustomThemeVar({
                elName,
                property: key,
                value: val,
              });
            }

            return '';
          })
          .filter(Boolean)
          .join('\n');
      }
      return entries(elementSettings)
        .map(([key, val]) => {
          if (val === undefined) return null;
          if (val === '') return null;

          if (key === 'textColor' || key === 'accentColor') {
            switch (val.type) {
              case 'value': {
                if (ColorStringStruct.is(val.colorValue)) {
                  return getCustomThemeVar({
                    elName,
                    property: key,
                    value: val.colorValue,
                  });
                }
                break;
              }
              case 'design-token': {
                if ('tokenName' in val) {
                  const tokenId = val.tokenName;
                  const token = tokensById[tokenId];
                  if (!token) {
                    console.warn('Missing token: ', val);
                    return '';
                  }
                  if (token.type === 'color') {
                    return getCustomThemeVar({
                      elName,
                      property: key,
                      value: token.value,
                    });
                  }
                }
                break;
              }
              default:
                return '';
            }
          } else if (FontSizeConfigStruct.is(val)) {
            if (val?.min) {
              return getCustomThemeVar({
                elName,
                property: key,
                value: `${val.min.value}${val.min.unit}`,
              });
            }
          } else {
            return getCustomThemeVar({
              elName,
              property: key,
              value: val,
            });
          }
          return '';
        })
        .filter(Boolean)
        .join('\n');
    })
    .join('\n');

  return typographyCss;
};

export const convertUiSettingsToFontFace = ({
  uiCustomFontSettings,
}: {
  uiCustomFontSettings: UiSettings['appearance']['customFonts'];
}): string => {
  if (!uiCustomFontSettings || !uiCustomFontSettings.length) return '';

  const fontFaceCss = uiCustomFontSettings
    .map((customFont) => {
      return `@font-face {
        ${
          customFont.family
            ? `font-family: "${customFont.family
                .replaceAll('"', '')
                .replace(/(^'|'$)/g, '')
                .replace(/(;$)/g, '')}";`
            : ''
        }
        ${customFont.style ? `font-style: ${customFont.style};` : ''}
        ${customFont.weight ? `font-weight: ${customFont.weight};` : ''}
        ${
          customFont.src?.length
            ? `src: ${customFont.src
                .map((src) => {
                  return src.type === 'url'
                    ? `${src.type}(${src.path}) format('${src.format}')`
                    : `${src.type}("${src.path}")`;
                })
                .join(', ')};`
            : ''
        }
        font-display: swap;
      }`;
    })
    .join('\n');

  return fontFaceCss;
};

/**
 * Turn the theme settings into actual <head> styles
 */
export const getThemeStyles = ({
  uiConfig,
  tokensById,
  selector,
}: {
  uiConfig: UiConfig;
  tokensById: Record<string, TokenData>;
  selector: string;
}) => {
  const { uiSettings } = convertUiConfigToUiSettings({
    uiConfig,
  });
  const styles = `
  ${convertUiTypographySettingsToCss({
    uiTypographySettings: uiSettings.appearance.pageElements,
    tokensById,
  })}
  ${convertUiNavSettingsToCss({
    uiNavSettings: uiSettings.appearance.navigation,
    tokensById,
  })}
  `.trim();
  const styleRule = styles ? `${selector} { ${styles} }` : '';
  const fontFaces = convertUiSettingsToFontFace({
    uiCustomFontSettings: uiSettings.appearance.customFonts,
  }).trim();

  return `${styleRule}${fontFaces}`.trim();
};

export { defaultUiSettings };
