export function scrollMainContentToElement({
  element,
  offset = 120,
  behavior = 'smooth',
}: {
  element: Element;
  offset?: number;
  behavior?: ScrollBehavior;
}) {
  const mainContent = document.querySelector('[data-target="mainContent"]');

  if (mainContent) {
    mainContent.scrollTo({
      top: mainContent.scrollTop + element.getBoundingClientRect().top - offset,
      behavior,
    });
  }
}
