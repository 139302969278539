import { sendUiEvent, useAppCtxSelector, useAppStateMatches } from '@/core';
import { useEffect } from 'react';
import { ProfileForm } from '../components/profile-form';

export const useProfileFormInterceptor = () => {
  const hasUserAndSite = useAppStateMatches(
    'usersAndSite.siteLoaded.loggedInUser.allInfo',
  );
  const siteId = useAppCtxSelector((ctx) => ctx.site?.meta?.siteId);
  const user = useAppCtxSelector((ctx) => ctx.user);

  useEffect(() => {
    if (!hasUserAndSite) return;
    if (!user?.membershipSiteIds?.includes(siteId)) return;
    if (user?.info?.responsibilityId) return;
    sendUiEvent([
      {
        type: 'modal.setContent',
        modal: {
          body: <ProfileForm />,
          title: 'Welcome Back',
          subtitle: "Let's make sure your profile is up to date.",
          testId: 'profile-form-interceptor',
          size: 'wide',
        },
      },
      'modal.triggerOpen',
    ]);
  }, [hasUserAndSite, siteId, user]);
};
