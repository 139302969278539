'use client';

import { useUiCtxSelector } from '@/core';

export const HeadTags = () => {
  const {
    title = 'Knapsack',
    description,
    favicon,
  } = useUiCtxSelector(({ headTags }) => headTags);
  return (
    <>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {favicon && <link rel="icon" href={favicon} />}
      <meta name="application-name" content="Knapsack" />
    </>
  );
};
