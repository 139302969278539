'use client';

import { Suspense } from 'react';
import dynamic from 'next/dynamic';
import { useUserRoleCanEdit, useAppStateMatches } from '@/core';
import { Skeleton } from '@knapsack/toby';
import { AppLoadingIndicator } from '@/components/app-loading-indicator/app-loading-indicator';
import { appVars } from '@/core/vars.css';
import { useKsLocation } from '@/utils/ks-location.hooks';
import { appShell, appChildren } from './app-layout.css';

const AppBar = dynamic(() => import('@/core/layout/app-bar/app-bar'));
const Portal = dynamic(() => import('@/core/layout/panels/portal'));

export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const roleCanEdit = useUserRoleCanEdit();
  const isSiteLoaded = useAppStateMatches('site.loaded');
  const ksLocation = useKsLocation();
  const isPatternEmbed = ksLocation.type === 'patternEmbed';
  const hasAppBar = roleCanEdit && isSiteLoaded && !isPatternEmbed;

  return (
    <div className={appShell()}>
      <Suspense
        fallback={<Skeleton style={{ height: appVars.layout.appBarHeight }} />}
      >
        {hasAppBar && <AppBar />}
      </Suspense>
      <div
        className={appChildren({
          hasAppBar,
        })}
      >
        <Suspense fallback={<AppLoadingIndicator />}>{children}</Suspense>
      </div>
      <Suspense>
        <Portal />
      </Suspense>
    </div>
  );
};
