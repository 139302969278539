'use client';

import dynamic from 'next/dynamic';
import { useAppStateMatches, sendAppEvent } from '@/core';
import { TextBody, ButtonPrimary } from '@knapsack/toby';
import { TextLink } from '@/components/text-link';

const ErrorPage = dynamic(() =>
  import('@/core/pages/error-page').then((mod) => mod.ErrorPage),
);

export const AuthChecker = ({ children }: { children: React.ReactNode }) => {
  // Possible bad local token
  const isAuthError = useAppStateMatches('user.authError') || false;
  // Response from Hasura that token provided cannot fetch claims/load user
  const userLoadingError =
    useAppStateMatches('user.loggedIn.loadingError') || false;
  if (!isAuthError && !userLoadingError) return children;
  return (
    <ErrorPage
      graphic="auth-error"
      title="Your Session Has Expired"
      message={
        <>
          <TextBody size="large" spacing="xsmall">
            To continue working, sign in again.
          </TextBody>
          <TextBody size="medium" color="subtle" spacing="xlarge">
            If this issue persists, please contact{' '}
            <TextLink color="subtle" href="mailto:help@knapsack.cloud">
              help@knapsack.cloud
            </TextLink>
          </TextBody>
          <ButtonPrimary
            label="Sign In"
            onTrigger={() => sendAppEvent('user.signOut')}
          />
        </>
      }
    />
  );
};
