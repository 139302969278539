import { useState, useEffect } from 'react';
import { scrollMainContentToElement } from './scroll-main-content';

export function useHashLinkHandler(): void {
  const [hash, setHash] = useState<string>('');

  useEffect(() => {
    setHash(window.location.hash);
    const onHashChange = () => {
      setHash(window.location.hash);
    };
    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }, []);

  useEffect(() => {
    // We need to ignore some hashes that contain specific strings from our auth provider.
    // Test for these strings and skip trying to query the DOM for them.
    // Prevent jumping to hash link for ignored hashes
    const ignoreHashes = ['access_token'];

    if (hash && !ignoreHashes.includes(hash)) {
      const id = setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''));
        if (!element) return;
        scrollMainContentToElement({ element });
      }, 2000);
      return () => clearTimeout(id);
    }
  }, [hash]);
}
