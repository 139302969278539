'use client';

import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { ApolloProvider, client } from '@/services/util-apollo-graphql.client';
import { queryClient } from '@/services/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { knapsackGlobal } from '@/global';

const AppProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  useEffect(() => {
    // Client-side plugins expect `React` to be a global
    window.React = React;
  }, []);
  useEffect(() => {
    knapsackGlobal.goToUrl = (url: string) => router.push(url);
  }, [router]);
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <DndProvider
          options={HTML5toTouch}
          /**
           * Setting this prevents the error "Cannot have two MultiBackends at the same time" seen when React Fast Refresh (aka HMR) is enabled.
           * See https://github.com/react-dnd/react-dnd/issues/3257#issuecomment-1239254032
           */
          context={window}
        >
          {children}
        </DndProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
};

export default AppProviders;
