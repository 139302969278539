'use client';

import {
  Badge,
  componentSpacing,
  Flex,
  Modal,
  SkeletonGroup,
  TextBody,
} from '@knapsack/toby';
import { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { commandBarModal } from './command-bar.css';
import { sendUiEvent, useUiStateMatches } from '../xstate';

const CommandMenu = dynamic(
  () => import('./wrapper').then((m) => m.CommandMenu),
  {
    ssr: false,
    loading: () => (
      <SkeletonGroup
        count={5}
        className={componentSpacing({
          marginBlock: 'medium',
          marginInline: 'small',
        })}
        skeletons={{
          animated: true,
          size: 'medium',
          style: { width: '100%' },
        }}
      />
    ),
  },
);

export function CommandModal() {
  const isOpen = useUiStateMatches('commandBar.opened');

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        // Close any open modal
        sendUiEvent('modal.triggerClose');
        sendUiEvent('commandBar.open');
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);
  return (
    <Modal
      bodyClassName={commandBarModal}
      opened={isOpen}
      // handled ourselves b/c we often back out of menus, then clear text, then close modal
      preventEscKeyClose
      onToggle={() => sendUiEvent('commandBar.close')}
      testId="command-menu"
      size="extraWide"
      instantOpen
      body={isOpen && <CommandMenu />}
      footer={
        <Flex gap="xsmall" align="center" justify="end">
          <TextBody size="small" color="subtle" spacing="none">
            Open with
          </TextBody>
          <Badge label="⌘" size="small" />
          <Badge label="K" size="small" />
        </Flex>
      }
    />
  );
}
